<template>
  <div class="Game">
    <div>
      <go-back-icon/>
    </div>
    <iframe :src="frameSrc" frameborder="0"></iframe>
  </div>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";
import {onMounted, onUnmounted} from "vue";
import GoBackIcon from "@/components/system/GoBackIcon";

let route = useRoute()
let router = useRouter()
let topicId = route.query.topicId
let baseurl;
if (process.env.NODE_ENV === 'development') {
  baseurl = 'http://localhost:3000/index.html'
} else if (process.env.NODE_ENV === 'production') {
  if (process.env.VUE_APP_TITLE === 'test') {
    baseurl = 'http://183.215.122.144:1562'
  } else if (process.env.VUE_APP_TITLE === 'build') {
    baseurl = 'https://img.dwhs.cn/atsgames/index.html'
  }
}

let frameSrc = baseurl + `?topicId=${topicId}`
const getMsg = (e) => {
  router.back()
}

onMounted(() => {
  window.addEventListener('message', getMsg)
})

onUnmounted(() => {
  window.removeEventListener('message', getMsg)
})

</script>

<style lang="less" scoped>
.Game {
  width: 100%;
  height: 100%;
  overflow: hidden;

  iframe {
    width: 100%;
    height: 100%;
  }
}
</style>
